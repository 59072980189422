<template>
  <div style="width: 80%;margin: 0 auto;padding-bottom: 50px;min-height: 60vh;">
    <div class="flex flex-sb fz-18" style="font-family: 黑体">
      <div class="flex">
        <div>支付金额：</div>
        <div>{{ payMount.toMoney() }}</div>
        <template v-if="originPayMount && payMount !== originPayMount">
          <div class="mg-l15">( 原合计：{{ originPayMount.toMoney() }})</div>
        </template>
      </div>
      <div class="flex" style="cursor: pointer;" @click="showDetails = !showDetails">
        <div style="user-select: none;">订单详情</div>
        <div>
          <i :class="[showDetails ? 'el-icon-caret-top' : 'el-icon-caret-bottom']"></i>
        </div>
      </div>
    </div>
    <div v-if="orders && orders.length > 0 && showDetails" style="transition: all .5s;">
      <template v-for="order in orders">
        <div class="mg-tb10" style="border: 1px #eee solid;">
          <div class="sctp-bg-white">
            <div
              class="sctp-flex sctp-mar-b10 sctp-pad15 sctp-flex-aic sctp-font-12 sctp-bg-gray sctp-info">
              <div style="margin-right: 30px;">订单号：{{
                  order.orderNo
                }}
              </div>
              <div>提交时间：{{ dateToString(order.submitTime) }}</div>
            </div>
            <template v-for="store in order.stores">
              <div class="sctp-flex sctp-mar-tb10 sctp-pad-lr15 sctp-flex-aic">
                <el-image
                  class="sctp-mar-r15"
                  style="width: 20px; height: 20px"
                  :src="store.shopLogo"
                  fit="cover"></el-image>
                <div>店铺：{{ store.shopName }}</div>
              </div>
              <div class="goods-list">
                <template v-for="product in store.products">
                  <el-row class="sctp-pad15 goods-item">
                    <el-col :span="14">
                      <div class="">
                        <div class="sctp-flex">
                          <el-image
                            class="sctp-mar-r15"
                            style="width: 80px; height: 80px"
                            :src="product.preview"
                            fit="cover"></el-image>
                          <div class="sctp-flex-item-1">
                            <div>
                              {{ product.productName }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="sctp-tc">¥{{ product.price.toMoney() }}</div>
                    </el-col>
                    <el-col :span="2">
                      <div class="sctp-tc">
                        x{{ product.amount }}
                      </div>
                    </el-col>
                    <el-col :span="3">
                      <div class="sctp-color-main sctp-tc">¥{{
                          product.amount.mul(product.price).toMoney()
                        }}
                      </div>
                    </el-col>
                  </el-row>
                </template>
              </div>
            </template>
          </div>
        </div>
      </template>
    </div>
    <div>
      <div class="payment-wrap">
        <div class="payment-item"
             :class="[{active: payWay === '1'}]"
             @click="payWay = '1'"
        >
          <el-image
            src="/images/pay/zfbpay.png"
            class="sctp-mar-r10"
            fit="contain"
          ></el-image>
        </div>
        <div class="payment-item"
             :class="[{active: payWay === '2'}]"
             @click="payWay = '2'"
        >
          <el-image
            src="/images/pay/wxpay.png"
            fit="contain"
          ></el-image>
        </div>
        <div class="payment-item"
             :class="[{active: payWay === '3'}]"
             @click="payWay = '3'"
        >
          <el-image
            src="/images/pay/balance.png"
            class="sctp-mar-r10"
            fit="contain"
          ></el-image>
        </div>
      </div>
<!--      <el-dialog-->
<!--        center-->
<!--       width="250px"-->
<!--        style="margin-top: 70px"-->
<!--        :visible="money">-->
<!--        <el-image-->
<!--          src="/images/temp/qrcode.png"-->
<!--          fit="cover"-->
<!--          style="height: 200px;width: 200px;"-->
<!--        ></el-image>-->
<!--      </el-dialog>-->
      <div>
        <el-button :disabled="!payWay" size="small" type="primary" @click="onPayClick">立即支付
        </el-button>
<!--        <el-button  :disabled="!payWay" size="small" type="primary" @click="onPayClick">模拟支付-->
<!--        </el-button>-->
        <el-dialog
          append-to-body
          center
          width="1000px"
          :close-on-click-modal="false"
          :close-on-press-escape="false"
          :destroy-on-close="true"
          :visible.sync="money"
          @close="closeInter"
        >
<!--          <s-please v-bind:price="price" v-bind:typename="typename"></s-please>-->
          <div id="please">


          <div id="pay" >
            <div style="color: black;padding-left: 180px; font-size: 18px;padding-right: 180px;padding-bottom: 10px" class="flex-rl" >{{typename}}</div>
            <div style="height: 2px;background-color: #C0C4CC;width: 100%;opacity: 0.3;"></div>
            <div class="flex-rc" :class="{'show':show}">
              <div>
                <div style="padding: 5px 0">
                  <div style="padding: 5px 0;color: black"><h3 >请使用{{typename}} [ 扫一扫 ] </h3></div>
                  <div style="padding: 5px 0"><h3>扫描二维码支付&nbsp;<w style="color: red;font-size: 25px">{{price}}</w>&nbsp;元</h3></div>
                </div>
                <div style="padding: 10px 0 ">
                  <iframe
                    v-if="typename=='支付宝支付'"
                    :srcdoc="zfbQrcode"
                  frameborder="no"
                  border="0"
                  marginwidth="0"
                  marginheight="0"
                  scrolling="no"
                  width="220"
                  height="220"
                  style="overflow: hidden;margin-top: 5px">
                  </iframe>

                  <!--         <el-image-->
                  <!--           id="wxImg"-->
                  <!--           src="/images/temp/wxsrcode.png"-->
                  <!--           fit="cover"-->
                  <!--           style="box-shadow: 0 2px 4px rgba(0,0,0, .12),0 0 6px rgba(0,0,0, .04);width: 230px;height: 230px"-->
                  <!--           v-if="typename=='微信支付'"-->
                  <!--         ></el-image>-->
                  <img id="wxImg" v-if="typename=='微信支付'">

                </div>
                <div><h5>二维码有效期时长为1小时，请尽快支付</h5></div>
                <div><h5>如无法支付，请点击<el-link type="primary" @click="freshQrcode">刷新</el-link></h5></div>
              </div>
              <div >
                <el-image

                  src="/images/temp/zfbscreen.png"
                  fit="cover"
                  style="box-shadow: 0 2px 4px rgba(0,0,0, .12),0 0 6px rgba(0,0,0, .04)"
                  v-if="typename=='支付宝支付'"
                ></el-image>
                <el-image

                  src="/images/temp/wxscreen.png"
                  fit="cover"
                  style="box-shadow: 0 2px 4px rgba(0,0,0, .12),0 0 6px rgba(0,0,0, .04);width: 300px;height: 350px;"
                  v-if="typename=='微信支付'"
                ></el-image>
              </div>
            </div>
          </div>
          </div>
        </el-dialog>

      </div>
    </div>
    <el-dialog
      append-to-body
      center
      width="500px"
      title="请输入支付密码"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      destroy-on-close
      :visible.sync="payPwdConfig.show">
      <div v-loading="payPwdConfig.checking" class="flex flex-center">
        <custom-password @success="payPwdConfig.inputEnd"></custom-password>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {order, wallet} from "@/apis/index";

export default {
  name: 'orderBy',
  components: {
    pay: () => import('@CMP/common/BuyPay'),
    CustomPassword: () => import('@CMP/common/PayPwd'),
  },
  props: {
    id: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      show:false,
      zfbQrcode:"",
      show1:true,
      timer:null,
      price: "",
      typename:"",
      showDetails: true,
      orders: null,
      orderNos:null,
      payWay: null,
      money:false,
      payPwdConfig: {
        show: false,
        checking: false,
        password: '',
        showDialog: () => {
          this.payPwdConfig.show = true;
        },
        closeDialog: () => {
          this.payPwdConfig.show = false;
        },
        inputEnd: (password) => {
          this.payPwdConfig.checking = true;
          wallet.checkPwd({
            userId: this.user.userId,
            password: password
          }).then(() => {
            this.payPwdConfig.closeDialog();
            this.onPay({
              payWay: this.payWay,
            })
          }).finally(() => {
            this.payPwdConfig.checking = false;
          });
        },
      },
    }
  },

  computed: {
    ids() {
      if (!this.id) {
        return []
      } else {
        return this.id.split('-')
      }
    },
    /**
     * 支付金额
     */
    payMount() {
      let payMount = 0
      if (this.orders) {
        payMount = this.orders.reduce(
          (previousValue, currentValue) => previousValue + currentValue.payAmount, 0)
      }
      this.price=payMount;

      return payMount
    },
    originPayMount() {
      let payMount = 0
      if (this.orders) {
        payMount = this.orders.reduce(
          (previousValue, currentValue) => previousValue + currentValue.originPayAmount
            || currentValue.payAmount, 0)
      }
      return payMount;
    },
  },
  methods: {
    freshQrcode() {
      if (this.payWay == 1){
        this.getZfbQrcode()
      }else if (this.payWay == 2){
        this.getQrCode()
      }
    },
    getOrder() {
      order.getOrder({
        orderIds: this.ids,
        userId: this.user.userId,
      }).then(res => {
        const {retdata = []} = res
        if (retdata.some(item => item.orderStatus !== 0)) {
          // 任何一个订单不是待支付状态跳转到订单详情页面
          this.goPage('/user/order')
        }
        this.orders = retdata;
        let list = []
        retdata.forEach(item=>{
          list.push(item.orderNo)
        })
        this.orderNos = list
      });
    },
    onPayClick() {
      let {payWay} = this;
      if (payWay === '3') {
        this.payPwdConfig.showDialog();
        return;
      }else{
        // console.log(this.money)
        this.money=true;
        // console.log(this.money)
      }
      // console.log(payWay)
      if(this.payWay==1){
        this.typename="支付宝支付";
        this.getZfbQrcode()
      }else if(this.payWay==2){
        this.typename="微信支付"
        this.getQrCode()
      }

      // var i = this.payMount();

      // this.$refs.shop.setPrice(i)
      // this.onPay({
      //   payWay: payWay,
      // })
    },
    onPay({payWay}) {
      order.orderPay({
        orderIds: this.ids,
        payWay,
        userId: this.user.userId
      }).then(res => {
        this.$message.success('支付成功');
        this.goPage('/order/payResult');
      });
    },
    getQrCode(){
      if (this.timer != null){
        clearInterval(this.timer)
      }
      this.$request.post({
        reqcode: '1275',
        reqdesc: '获取二维码',
        price : this.price,
        title : this.typename,
        userId : this.user.userId,
        outTradeNo: this.orders[0].orderNo
      }).then(res=>{
        new QRious({
          element:document.getElementById('wxImg'),
          size:220,
          level:'H',
          value:res.retdata.code_url
        });
        this.getQrCodeSta(res.retdata.out_trade_no)
      })
    },
    getQrCodeSta(outOrderId){
      this.timer = setInterval(()=>{
        this.$request.post({
          reqcode: '1276',
          reqdesc: '获取二维码的状态',
          outTradeNo : outOrderId,
          userid : this.user.userId,
          orderNOs : this.orderNos
        }).then(response=>{
          if (response.retdata== "SUCCESS"){
            clearInterval(this.timer)
            this.show = true
            this.money =false
            order.orderPay({
              orderIds: this.ids,
              payWay:this.payWay,
              userId: this.user.userId
            }).then(res => {
              this.$message.success('支付成功');
              this.goPage('/order/payResult');
            });
          }
          if (response.retdata== "ERROR"){
            clearInterval(this.timer)
            this.$message.error("支付错误")
          }
        })
      },3000)
    },
    getZfbQrcode(){
      this.$request.post({
        reqcode: '1277',
        reqdesc: '获取支付宝二维码',
        userid:this.user.userId,
        price : this.price,
        title : this.typename,
        orderIds: this.ids,
        outTradeNo: this.orderNo
      }).then(res=>{
        this.zfbQrcode = res.retdata.body
      })
    },
    closeInter(){
      clearInterval(this.timer)
    },
  },
  beforeMount() {
    this.getOrder();
    let scriptLoad = document.createElement('script')
    scriptLoad.type = 'text/javascript'
    scriptLoad.src = '/resources/js/qrious.js'
    document.body.appendChild(scriptLoad);
  }
}
</script>

<style scoped lang="scss">

.sctp-block.el-radio {
  display: flex;
  margin-right: 0;
  margin-left: 0 !important;
  margin-bottom: 10px;

  ::v-deep .el-radio__label {
    flex: 1;
    display: inline-flex;
    justify-content: space-between;
  }
}

.payment-wrap {
  ::v-deep {
    display: flex;
    margin: 15px 0;

    .payment-item {
      display: flex;
      margin-right: 10px;
      border: 1px solid #ccc;
      align-items: center;
      cursor: pointer;

      &.active {
        border: 2px solid #0e7fe1;
      }

      img {
        height: 45px;
        width: 105px;
      }
    }
  }
}
#please{
  margin: 0 0;
  text-align: center;
  padding: 0 0;
  height: 500px;
}
.flex-rc{
  display: flex;
  flex-direction:row ;
  justify-content: space-around;
}
.flex-cc{
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.flex-rl{
  display: flex;
  flex-direction: row;
  justify-content: left;
}
.show{
  display: none;
}
</style>
